<template>
  <div class="dui_address">
    <!-- 顶部导航 -->
    <van-nav-bar class="topnav" title left-arrow @click-left="toBack" :border="false" />
    <!-- 内容 -->
    <div class="con">
      <div class="pic">
        <img src="../../../public/statics/images/my/address.png" alt />
      </div>
      <div class="dui">兑换成功</div>
      <div class="des">请填写收货地址</div>
      <div class="kuang">
        <div class="lable">
          <span>*</span>详细地址
        </div>
        <div class="inp">
          <van-field v-model="address" label placeholder="市县、乡镇、街道、楼牌号等" />
        </div>
      </div>
      <!-- 按钮 -->
      <!-- <div class="btn" @click="addAddress">确定</div> -->
      <van-button style="display:block;" :loading="submitLoading" round plain class="btn" type="info" @click="addAddress">确定</van-button>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      submitLoading: false, // 按钮加载状态
      address: "", //地址
      orderid: "" //订单id
    };
  },
  created() {
    this.orderid = this.$route.query.orderid;
  },
  methods: {
    toBack() {
      this.$router.go(-1);
    },
    addAddress() {
      var that = this;
      // console.log("address",this.address);
      if (that.address != "") {
        that.submitLoading = true
        var params = {
          token: Cookies.get("spToken"),
          id: that.orderid,
          address: that.address
        };
        that.$api.integral.address(params).then(res => {
          that.submitLoading = false
            if(res.code == 200){
                that.$router.push({
                    path:"/dui_record"
                })
            }
        })
      } else {
        that.$toast('请填写详细地址')
      }
    }
  }
};
</script>

<style>
.dui_address .con .btn{
  border: none;
}
</style>